.container {
  height: 100vh;
  overflow: hidden;
}

.content {
  display: inline-flex;
  transition: transform 500ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.content.vertical {
  flex-direction: column;
  transform: translateY(calc(-100% * var(--current) / var(--count)));
}

.content.horizontal {
  flex-direction: row;
  transform: translateX(calc(-100% * var(--current) / var(--count)));
}

.panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;

  overflow: hidden;
}
