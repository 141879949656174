* {
  box-sizing: border-box;
  user-select: none;
}

html,
body {
  padding: 0;
  margin: 0;

  --text-color: #422c05;
  --primary-color: #7b6237;
  --highlight-color: #a59258;
  --active-color: #bfaf7b;
  --disabled-color: #e6c156 /*c5b380*/;

  background-color: #ffd24c;
}

html,
body,
input,
select,
button {
  font-family: Times, Times New Roman, Georgia, serif;
  font-size: 3.5vmin;
}

h1,
input,
select,
button {
  color: var(--text-color);
}

a {
  text-decoration: none;
  color: var(--primary-color);
  transition: color 0.25s ease-in-out;
}

a:not([href]) {
  color: var(--disabled-color);
}

a[href]:hover {
  color: var(--highlight-color);
}

a[href]:active {
  color: var(--active-color);
}

a > .SVGInline > .SVGInline-svg {
  width: 1em;
  height: 1em;
  vertical-align: text-bottom;
}

input,
select {
  background-color: #fff9e0;
  border: 1px solid var(--text-color);
  border-radius: 2px;

  user-select: initial; /* iOS needs this */
}
