@keyframes pulse {
  0% {
    opacity: 0;
  }

  70% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

.square {
  display: inline-block;

  background-color: #000000;
  width: 2vmin;
  height: 2vmin;
  margin: 1vmin;

  animation-name: pulse;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-duration: 0.5s;
}

.square:nth-child(1) {
  animation-delay: 0;
}

.square:nth-child(2) {
  animation-delay: 0.125s;
}

.square:nth-child(3) {
  animation-delay: 0.25s;
}
