.overlay {
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  pointer-events: none;

  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-content: space-between;

  opacity: 0.8;
}

.overlay > * {
  margin: 0.5em;
}

.overlay > * {
  pointer-events: all;
}

.off-center {
  padding-right: 20vw;
  padding-bottom: 20vh;
}

.form-fields {
  display: grid;
  grid-template-columns: 1fr 2fr;
  text-align: right;
  grid-gap: 0.5vh 1vh;
}

.back-arrow {
  position: absolute;
  left: -1em;
}

.back-arrow-container {
  position: relative;
  top: 0;
  left: 0;
  line-height: 1em;
}

.overlay-button:global(.SVGInline) > :global(.SVGInline-svg) {
  font-size: 230%;
}

.navigate-answers {
  display: flex;
  flex-direction: column;
}

.bright-link {
  color: var(--text-color);
}
